<!-- eslint-disable camelcase -->
<!--
 * @Descripttion: 抽奖信息配置页面
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-03-31 10:39:13
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-06-25 10:15:10
-->
<template>
  <Header></Header>
  <div class="setting-box" v-loading="isLoading">
    <el-form
      :model="settingForm"
      :rules="ruleForm"
      ref="settingFormRef"
      :show-message="false"
      >
      <div class="basic">
        <p class="setting-title">基础设置</p>
        <el-form-item :label="`${item.label} :`" :prop="item.value" v-for="item in basicList" :key="item.label">
          <el-input v-model="settingForm[item.value]" :placeholder="item.placeholder" clearable :maxlength="item.maxlength"></el-input>
        </el-form-item>
      </div>
      <div class="conditions">
        <p class="setting-title">参与条件</p>
        <template v-for="{label, value, show = true} in isTopicList" :key="value">
          <el-form-item v-show="show" :label="`${label} :`" :prop="value"  >
            <template v-if="label == '参与用户'">
              <div class="user-item">
                <p class="selected-area active" @click="getSelectUser"><i></i> 选择范围</p>
                <el-scrollbar height="88px">
                  <span class="selected-tag" v-for="tag in settingForm.users" :key="tag.id">
                    {{tag.name}}
                    <i class="tag-close" @click="delSelectedTag(tag)"></i>
                  </span>
                </el-scrollbar>
              </div>

              <div class="dynamic-item">
                <p :class="['selected-area', {'active': !conditionsList[1].isTopic}]" @click="getTopic"><i></i>动态 <span class="tips-span">勾选后，只有发布过动态的用户才能参与抽奖</span></p>
              </div>
            </template>
            <template v-else-if="label === '活动话题'">
              <el-input class="topic-input" v-model="settingForm[value]" placeholder="可输入多个，使用英文分号分割，不需要#" clearable maxlength="100"></el-input>
            </template>
            <template v-else-if="label === '点赞数'">
              <div class="count-item">
                <div class="count-input">
                  <svg :class="{ 'disable': settingForm[value] === 0 }" @click="countReduce" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x=".5" y=".5" width="27" height="27" rx="5.5" fill="#F6F9FA" stroke="#F1F1F1"/><rect x="21" y="13" width="2" height="14" rx="1" transform="rotate(90 21 13)" fill="#C4C4C4"/></svg>
                  <el-input v-model="settingForm[value]" @input="validateInput"></el-input>
                  <svg @click="countAdd" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x=".5" y=".5" width="27" height="27" rx="5.5" fill="#F6F9FA" stroke="#F1F1F1"/><rect x="13" y="7" width="2" height="14" rx="1" fill="#C4C4C4"/><rect x="21" y="13" width="2" height="14" rx="1" transform="rotate(90 21 13)" fill="#C4C4C4"/></svg>
                </div>
                <span class="tips-span">所选范围内任一条动态的点赞数达到或超过设定数值的用户才能参与抽奖</span>
                <!-- <el-input-number v-model="settingForm[value]" :min="0" /> -->

              </div>
            </template>
            <template v-else-if="label === '起止时间' || label === '入职时间'">
              <div class="duration-item">
                <el-date-picker
                  v-model="settingForm[value]"
                  type="datetimerange"
                  unlink-panels
                  format="YYYY-MM-DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  range-separator="~"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  class="setting-duration"
                  :default-value="defaultTime"
                  :default-time="defaultTime"
                  @change="timeChange"
                />
                <p class="duration-text">{{
                  !conditionsList[1].isTopic ?
                  '所选范围内发布过动态的用户，才能参与抽奖' :
                '入职时间在所选时间段内的用户，才能参与抽奖'
                }}
                </p>
              </div>
              <!-- <div class="dynamic-item set-count" v-show="!conditionsList[1].isTopic">
                <p :class="['selected-area', {'active': conditionsList[3].setMin}]" @click="setMinCount"><i></i>点赞 <span>勾选后，任一条动态的点赞数达到或超过设定数值的用户才能参与抽奖</span></p>
              </div> -->
            </template>
          </el-form-item>
        </template>
      </div>
      <div class="prizes">
        <p class="setting-title">奖项设置</p>
        <div v-for="(prizesItem, index) in settingForm.prize_list" :key="index" class="prizes-setting">
          <el-form-item
            v-for="item in prizesSetting"
            :label="`${item.label} :`"
            :rules="rulesPrize[`prize_list.${item.value}`]"
            :prop="`prize_list.${index}.${item.value}`"
            :key="item.label"
            >
            <el-input
              v-model="prizesItem[item.value]"
              :placeholder="item.value === 'all_num' ? '' : '请输入'"
              :class="[{'numInput': item.value === 'all_num'}]"
              v-if="item.value !== 'picture'"
              :clearable="item.value !== 'all_num'"
              :maxlength="item.maxlength"
              ></el-input>
            <div v-else-if="item.value === 'picture'" class="prizes-show">
              <el-image
                class="prize-img"
                :src="prizesItem[item.value] || require('@/assets/img/prize/default-prizeimg.png')"
                :preview-src-list="prizeImgList"
                :hide-on-click-modal="true"
                :append-to-body="true"
                >
              </el-image>
              <el-upload
                :show-file-list="false"
                :before-upload="beforeUpload.bind(null, index)"
                accept=".png,.jpg"
                action=""
                :limit="1"
                class="prize-upload"
              >
                <p>+ 上传替换</p>
              </el-upload>
            </div>
          </el-form-item>
          <div class="prize-addOrdel">
            <p class="prize-del" v-if="settingForm.prize_list.length > 1" @click="delPrize(index)">
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x=".5" y=".5" width="27" height="27" rx="5.5" fill="#F6F9FA" stroke="#F1F1F1"/><rect x="21" y="13" width="2" height="14" rx="1" transform="rotate(90 21 13)" fill="#C4C4C4"/></svg>
            </p>
            <p class="prize-add" @click="addPrize(index)">
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x=".5" y=".5" width="27" height="27" rx="5.5" fill="#F6F9FA" stroke="#F1F1F1"/><rect x="13" y="7" width="2" height="14" rx="1" fill="#C4C4C4"/><rect x="21" y="13" width="2" height="14" rx="1" transform="rotate(90 21 13)" fill="#C4C4C4"/></svg>
            </p>
          </div>
        </div>
      </div>
      <el-form-item class="draw-button">
        <el-button :class="['get-link', {'is-disabled': disabledBtn}]" @click="getDrawLink()" :disabled="disabledBtn">生成链接</el-button>
        <el-button :class="['go-draw', {'is-disabled': disabledBtn}]" @click="toDrawLottery(1)" :disabled="disabledBtn">开始抽奖</el-button>
      </el-form-item>
    </el-form>
    <!-- 抽奖选择用户范围 -->
    <el-dialog v-model="selectVisible" custom-class="select-user-dialog"
      :width="651" title="参与人选择范围" :show-close="false" @close="hideSelectUser">
        <SelectUserRange ref="selectUserRef"
          :receiveIds="receiveIds"
          :receiveInfo="receiveList"
          :noMaxNum="true"
        ></SelectUserRange>
        <template #footer>
          <div class="button-box">
            <el-button class="back-button" type="primary" size="small" @click="hideSelectUser" >取消</el-button>
            <el-button class="sure-button" type="primary" size="small" @click="addReceiveUser">确定</el-button>
          </div>
      </template>
      </el-dialog>

      <!-- 生成链接弹窗 -->
    <el-dialog v-model="createLinkVisible" custom-class="create-link-dialog"
      :width="556" :show-close="false" @close="hideCreateLink">
      <template #title>
        <div class="title">
          <p>生成链接</p>
          <span class="close-icon" @click="hideCreateLink"></span>
        </div>
      </template>
      <div class="copy-content">
        <p>{{copyUrl}}</p>
        <el-button class="copy-button" @click="copyDrawLink">复制链接</el-button>
      </div>
      <template #footer>
        <div class="button-box">
          <el-button class="back-button" size="small" @click="hideCreateLink" >取消</el-button>
          <el-button class="sure-button" type="primary" size="small" @click="openPrizeScreen">开始抽奖</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { computed, reactive, toRefs, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { uploadFile } from '@/apis/userCenter.js'
import { getTeamList, getTeamUserList } from '@/apis/journal.js'
import { dialogMsg } from '@/utils/dialogMsg.js'
import { setPrizeRule, getRuleInfo, getDeptInfo } from '@/apis/prize.js'
import useClipboard from 'vue-clipboard3'
import { reBackTime } from '@/utils/tools'

export default {
  components: {
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      settingForm: {
        title: '',
        bg_music: '',
        users: [],
        topics: '',
        duration: null,
        prize_list: [
          {
            title: '',
            award_name: '',
            all_num: 1,
            picture: ''
          },
          {
            title: '',
            award_name: '',
            all_num: 1,
            picture: ''
          }
        ],
        likes_count: 0
      },
      ruleForm: {
        title: [
          { required: true, message: '不能为空', trigger: ['change', 'blur'] }
        ],
        users: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ]
      },
      rulesPrize: {
        'prize_list.title': [
          { required: true, message: '不能为空', trigger: ['change', 'blur'] }
        ],
        'prize_list.all_num': [
          { required: true, message: '不能为空', trigger: ['change', 'blur'] },
          { pattern: /^[1-9]\d*$/, message: '数值只能为正整数', trigger: ['change', 'blur'] }
        ]
      },
      settingFormRef: null,
      basicList: [
        {
          label: '活动标题',
          placeholder: '请输入',
          value: 'title',
          maxlength: 30
        },
        {
          label: '背景音乐',
          placeholder: '请输入链接',
          value: 'bg_music'
        }
      ],
      conditionsList: [
        {
          label: '参与用户',
          value: 'users'
        },
        {
          isTopic: true,
          label: '活动话题',
          value: 'topics'
        },
        {
          label: '点赞数',
          value: 'likes_count',
          show: computed(() => !state.conditionsList[1].isTopic) // 是否显示
        },
        {
          label: computed(() => !state.conditionsList[1].isTopic ? '起止时间' : '入职时间'),
          value: 'duration'
        }
      ],
      prizesSetting: [
        {
          label: '奖项名称',
          value: 'title',
          maxlength: 10
        },
        {
          label: '奖品',
          value: 'award_name',
          maxlength: 20
        },
        {
          label: '奖项数量',
          value: 'all_num',
          maxlength: 3
        },
        {
          label: '奖品图片',
          value: 'picture'
        }
      ],
      allSelect: true, // 是否全选
      allUsersCount: 0, // 所有用户总数
      companyID: 0, // 公司对应id
      companyInfo: [], // 公司信息
      isTopicList: computed(() => { return state.conditionsList.filter(item => !item.isTopic) }),
      prizeImgList: computed(() => {
        return state.settingForm.prize_list.map(item => {
          return item.picture ? item.picture : require('@/assets/img/prize/default-prizeimg.png')
        })
      }),
      receiveList: [],
      userIds: [],
      receiveIds: computed(() => { return state.receiveList.map(item => item.dept_id || item.ding_user_id || item.user_id) }),
      allUsersInfo: [],
      selectUserRef: null,
      selectVisible: false,
      createLinkVisible: false,
      toRuleID: null,
      copyUrl: computed(() => `${process.env.VUE_APP_LOGIN_URL}prize/setting?ruleID=${state.toRuleID}`),
      disabledBtn: true,
      prevLevelIds: {},
      defaultTime: computed(() => {
        const time = new Date().getTime()
        const date = reBackTime(time)
        const leftTiem = new Date(date.year, date.month, date.day, 0, 0, 0)
        const rightTiem = new Date(date.year, date.month + 1, date.day, 0, 0, 0)
        return [leftTiem, rightTiem]
      }),
      isLoading: false
    })
    onMounted(() => {
      getTeamInfo()
    })
    watch(() => [state.settingForm.title, state.settingForm.prize_list],
      (newValue) => {
        const arr = newValue.filter(item => {
          return typeof (item) === 'string' ? !item : !!item.filter(i => !i.title || !i.all_num).length
        })
        state.disabledBtn = !!arr.length
      }, { deep: true })

    watch(() => [state.receiveList, state.allSelect], (newValue) => {
      if (state.allSelect) {
        state.settingForm.users = [{
          id: state.companyID,
          name: '全部',
          type: 0 // 0 标识 部门; 1 标识 用户
        }]
      } else {
        state.settingForm.users = state.receiveList.map(item => {
          return {
            id: item.dept_id || item.id,
            name: item.name,
            type: item.dept_id ? 0 : 1 // 0 标识 部门; 1 标识 用户
          }
        })
      }
    }, { deep: true })

    const initData = () => {
      getRuleInfo({ rule_id: route.query.ruleID })
        .then(res => {
          if (res.code === 0) {
            // eslint-disable-next-line camelcase
            const { title, topics, bg_music, selected_depts, selected_users, ext, prize_list, start_time, end_time, likes_count } = res.data
            state.settingForm = { title, topics, bg_music, likes_count }
            state.prevLevelIds = !Array.isArray(ext[0]) ? ext[0] : {}
            if (selected_depts.length && selected_depts[0] === state.companyID) {
              state.receiveList = state.companyInfo
            } else {
              state.allSelect = false
              let userData = selected_users.length
                ? state.allUsersInfo.filter(item => selected_users.includes(item.id)) : []
              if (Object.keys(state.prevLevelIds).length !== 0) {
                userData = userData.map(item => {
                  return {
                    prevLevelId: state.prevLevelIds[res.data.id + '_prev'],
                    ...item
                  }
                })
              }
              state.receiveList.push(...userData)
              getDeptData(selected_depts)
            }
            state.conditionsList[1].isTopic = res.data.is_tick_feed === 0
            // eslint-disable-next-line camelcase
            state.settingForm.duration = (start_time && end_time) ? [start_time, end_time] : null
            state.settingForm.prize_list = prize_list.map(item => {
              return { all_num: item.all_num, title: item.title, award_name: item.award_name, picture: item.picture }
            })
          } else {
            dialogMsg('error', res.msg)
          }
        })
    }
    // 获取每个部门信息
    const getDeptData = (arr) => {
      if (arr.length) {
        arr.forEach(i => {
          getDeptInfo({ dept_id: i })
            .then(res => {
              if (res.code === 0) {
                res.data.prevLevelId = state.prevLevelIds[res.data.dept_id + '_prev']
                state.receiveList.push(res.data)
              }
            })
        })
      }
    }
    // 获取公司所有组织架构
    const getTeamInfo = () => {
      const data = { type: 2 }
      getTeamList(data).then(res => {
        if (res.code === 0) {
          state.allUsersCount = res.data.dept_count
          state.companyID = res.data.dept_id
          !!res.data.children && state.companyInfo.push(...res.data.children)
          !!res.data.user && state.companyInfo.push(...res.data.user)
          if (route.query.ruleID) {
            getAllUser(res.data.dept_id)
          } else {
            state.receiveList = state.companyInfo
          }
        }
      })
    }
    // 获取所有用户
    const getAllUser = (id) => {
      const data = { dept_id: id }
      getTeamUserList(data).then(res => {
        if (res.code === 0) {
          state.allUsersInfo = res.data
          initData()
        }
      })
    }

    // 增加用户
    const addReceiveUser = () => {
      state.userIds = []
      state.receiveList = JSON.parse(JSON.stringify(state.selectUserRef.rightData))
      if (!state.receiveList.length) { state.allSelect = true }
      state.receiveList.forEach(item => {
        if (item.dept_id) {
          getTeamUserList({ dept_id: item.dept_id }).then(res => {
            res.data.forEach(user => {
              setReceiveUser(user)
            })
          })
        } else {
          setReceiveUser(item)
        }
      })
      hideSelectUser()
    }
    // 设置接收人是否可删除
    const setReceiveUser = (item) => {
      const user = JSON.parse(JSON.stringify(item))
      // 去重添加
      !state.userIds.includes(user.id) && state.userIds.push(user.id)
      state.allSelect = state.allUsersCount === state.userIds.length
    }
    // 开启用户选择弹窗
    const getSelectUser = () => {
      state.selectVisible = true
      if (state.allSelect) {
        state.receiveList = state.companyInfo
      }
    }
    // 关闭用户选择弹窗
    const hideSelectUser = () => {
      state.selectVisible = false
      // 重置数据
      state.selectUserRef.resetData()
    }
    // 删掉选择用户或者部门
    const delSelectedTag = (value) => {
      if (state.settingForm.users.length > 1 && value) {
        const index = state.receiveList.findIndex(item => (item.dept_id || item.id) === value.id)
        state.receiveList.splice(index, 1)
      } else {
        dialogMsg('warning', '至少保留一项，不可删除')
      }
    }
    // 获取话题
    const getTopic = () => {
      state.conditionsList[1].isTopic = !state.conditionsList[1].isTopic
      state.settingForm.topics = ''
      // 清空点赞数
      if (state.conditionsList[1].isTopic) {
        state.settingForm.likes_count = 0
      }
    }

    // 设置最小点赞数量
    const setMinCount = () => {
      state.conditionsList[3].setMin = !state.conditionsList[3].setMin
    }

    // 图片上传
    const beforeUpload = (index, file) => {
      // 格式要求 jpg/png
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJPG) {
        dialogMsg('warning', '允许上传图片的类型为png和jpg')
      }
      // 大小要求 10M
      const isLt1M = file.size / 1024 / 1024 < 10
      if (!isLt1M) {
        dialogMsg('warning', '允许上传图片的大小限制为10M')
      }
      if (isJPG && isLt1M) {
        uploadFile(file, 1, () => {}, 10).then((res) => {
          if (res.code === 0) {
            state.settingForm.prize_list[index].picture = res.data.url
          } else {
            res.code === 17 ? dialogMsg('error', '文件名长度不能超过191个字符（不分数字、字母和汉字）') : dialogMsg('error', '上传图片失败')
          }
        }).catch((err) => {
          console.log(err)
        })
      }
    }
    // 删除奖品
    const delPrize = (index) => {
      state.settingForm.prize_list.splice(index, 1)
    }
    // 添加奖品
    const addPrize = (index) => {
      const data = {
        title: '',
        award_name: '',
        all_num: 1,
        picture: ''
      }
      state.settingForm.prize_list.splice(index + 1, 0, data)
    }
    // 获取用户或者部门
    const getUserOrDeptID = (value, type) => {
      const selectType = type === 'user' ? 1 : 0

      return value.filter(item => item.type === selectType).map(i => i.id)
    }
    // 触发抽奖配置上传
    const getRuleID = (type) => {
      // eslint-disable-next-line camelcase
      const { title, topics, bg_music, prize_list, users, duration, likes_count } = state.settingForm
      if (!state.allSelect) {
        state.receiveList.forEach(item => {
          if (item.prevLevelId) {
            item.dept_id && (state.prevLevelIds[item.dept_id + '_prev'] = item.prevLevelId)
            item.id && (state.prevLevelIds[item.id + '_prev'] = item.prevLevelId)
          }
        })
      } else {
        state.prevLevelIds = {}
      }
      const data = {
        title,
        topics,
        bg_music,
        prize_list,
        selected_users: getUserOrDeptID(users, 'user'),
        selected_depts: getUserOrDeptID(users, 'dept'),
        is_tick_feed: state.conditionsList[1].isTopic ? 0 : 1,
        start_time: duration ? duration[0] : '',
        end_time: duration ? duration[1] : '',
        is_send_link: type,
        ext: [state.prevLevelIds],
        likes_count
      }
      // if (data.title) return
      return new Promise((resolve, reject) => {
        setPrizeRule(data).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    }
    // 生成抽奖链接
    const getDrawLink = () => {
      state.settingFormRef.validate((valid) => {
        if (valid) {
          state.isLoading = true
          getRuleID(1)
            .then(res => {
              state.isLoading = false
              if (res.code === 0) {
                state.toRuleID = res.data.rule_id
                state.createLinkVisible = true
              } else {
                dialogMsg('error', res.msg)
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
    }

    // 开始抽奖
    const toDrawLottery = () => {
      state.settingFormRef.validate((valid) => {
        if (valid) {
          state.isLoading = true
          getRuleID(0)
            .then(res => {
              state.isLoading = false
              if (res.code === 0) {
                state.toRuleID = res.data.rule_id
                const routeUrl = router.resolve({ name: 'prizeScreen', query: { ruleID: state.toRuleID } })
                window.open(routeUrl.href, '_blank')
              } else {
                dialogMsg('error', res.msg)
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
    }

    // 关闭生成链接弹窗
    const hideCreateLink = () => {
      state.createLinkVisible = false
    }
    // 复制生成链接
    const { toClipboard } = useClipboard()
    const copyDrawLink = async () => {
      const res = await toClipboard(state.copyUrl)
      if (res.text) {
        dialogMsg('success', '复制成功')
      }
    }

    // 新窗口打开大屏展示页面
    const openPrizeScreen = () => {
      const routeUrl = router.resolve({ name: 'prizeScreen', query: { ruleID: state.toRuleID } })
      window.open(routeUrl.href, '_blank')
    }
    // 时间选择确认回调
    const timeChange = (date) => {
      if (date) {
        if (date[0] === date[1]) {
          state.settingForm.duration = null
          dialogMsg('warning', '时间不能相同')
        }
      }
    }

    // 计数自减少
    const countReduce = () => {
      if (state.settingForm.likes_count > 0) {
        state.settingForm.likes_count = Number(state.settingForm.likes_count) - 1
      }
    }

    // 计数自增
    const countAdd = () => {
      state.settingForm.likes_count = Number(state.settingForm.likes_count) + 1
    }

    const validateInput = (value) => {
      if (isNaN(value)) {
        state.settingForm.likes_count = 0
      }
    }

    return {
      ...toRefs(state),
      router,
      hideSelectUser,
      addReceiveUser,
      getTopic,
      setMinCount,
      beforeUpload,
      getDrawLink,
      toDrawLottery,
      delSelectedTag,
      hideCreateLink,
      copyDrawLink,
      delPrize,
      addPrize,
      openPrizeScreen,
      getSelectUser,
      timeChange,
      countReduce,
      countAdd,
      validateInput
    }
  }
}

</script>

<style lang="less">
.setting-box{
  max-width: 1120px;
  width: 100%;
  position: relative;
  margin: 73px auto 0;
  padding-bottom: 90px;
  z-index: 1;
  .el-form{
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 6px;
    padding: 0px 30px 14px 100px;
  }
  .basic{
    .setting-title{
      margin: 28px 0 25px;
    }
  }
  .conditions{
    .setting-title{
      margin: 50px 0 30px;
    }
  }
  .prizes{
    .setting-title{
      margin: 50px 0 32px;
    }
  }
  .basic,
  .conditions{
    .el-form-item__label{
      width: 90px;
    }
    .el-input{
      width: 500px;
    }
  }
  .prizes{
    .el-form-item:nth-of-type(1){
      .el-form-item__label{
        width: 90px;
      }
    }
  }
  .setting-title{
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: @default-text-color;
  }

  .el-form-item{
    margin-bottom: 20px;
    .el-form-item__label{
      color: @default-text-color;
      margin-left: 20px;
    }
    .el-input{
      line-height: 36px;
      .el-input__inner{
        padding: 9px 30px 9px 10px;
        width: 500px;
        height: 36px;
        line-height: 36px;
        background: #F6F9FA;
        border: 1px solid #F1F1F1;
        border-radius: 6px;
      }
      .el-input__icon{
        line-height: 36px;
      }
    }
    .count-item,.count-input{
      display: flex;
        align-items: center;
    }

    .count-input {
        display: flex;
        align-items: center;
        .el-input{
          width: 94px;
          height: 28px;
          line-height: 28px;
        }
        .el-input__inner{
          width: 80px;
          margin: 0 8px;
          height: 28px;
          line-height: 28px;
          padding: 0;
         text-align: center;
        }
        svg{
          cursor: pointer;
          &.disable{
            cursor: not-allowed;
          }
        }
    }
    &.is-error{
      .el-input__inner{
           border-color: var(--el-color-danger);
      }
    }
  }
  .user-item,
  .dynamic-item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &.set-count{
      margin-top: 20px
    }
  }
  .user-item{
    .el-scrollbar{
      margin: 20px 0;
      width: 500px;
      .el-scrollbar__wrap{
        width: 100%;
        height: 88px;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 10px 10px 0;
      }
      .el-scrollbar__view{
        display: flex;
        flex-wrap: wrap;
      }
      .selected-tag{
        padding: 5px 6px 5px 10px;
        background: #F6F9FA;
        border: 1px solid #F1F1F1;
        border-radius: 4px;
        font-weight: 400;
        font-size: 12px;
        color: #44444F;
        margin: 0 8px 10px 0;
        display: flex;
        align-items: center;
        line-height: 1;
        &.no-close{
          padding: 5px 10px;
        }
        i.tag-close{
          width: 11px;
          height: 12px;
          display: inline-block;
          background: url(../../../../assets/img/prize/tag-close-icon.svg);
          margin-left: 3px;
          cursor: pointer;
        }
      }
    }
  }
  .selected-area{
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 12px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: @active-text-color;
    &:last-child{
      margin-top: 0;
    }
    i{
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 1px solid @active-text-color;
      border-radius: 2px;
      margin-right: 10px;
    }
    &.active{
      i{
        background: @active-text-color url('../../../../assets/img/common/selected-icon.svg') no-repeat center;
      }
    }
  }
  .count-item{
    display: flex;
  }

  .tips-span{
    font-size: 12px;
    color: @default-text-color;
    opacity: 0.5;
    margin-left: 10px;
    line-height: 20px;
  }

  .duration-item{
    display: flex;
    align-items: center;
    .setting-duration{
      width: 400px;
      height: 36px;
      background: #F6F9FA;
      border: 1px solid #F1F1F1;
      border-radius: 6px;
      font-size: 13px !important;
      .el-range-input{
        background: #F6F9FA;
      }
      .el-icon-time{
        order: 1;
        height: 100%;
        line-height: 30px;
        &::before{
          content: '';
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url('../../../../assets/img/prize/datepicter-icon.svg') no-repeat;
          margin-top: 4px;
        }
      }
      .el-range__close-icon{
        height: 100%;
        line-height: 30px;
      }
      .el-range-separator{
        width: 10%;
        line-height: 29px;
      }
    }
    .duration-text{
      font-size: 12px;
      line-height: 16px;
      color: @default-text-color;
      opacity: 0.5;
      margin-left: 20px;
    }
  }
  .prizes-setting{
    display: flex;
    .el-input{
      line-height: 34px;
      .el-input__inner{
        width: 150px;
        height: 34px;
        line-height: 34px;
      }
      &.numInput{
        .el-input__inner{
          width: 46px;
          padding: 7px 8px;
          line-height: 1px!important;
        }
      }
      .el-input__icon{
        line-height: 34px;
      }
    }
    .prizes-show{
      display: flex;
      .prize-upload{
        line-height: 32px;
        p{
          width: 100px;
          height: 32px;
          line-height: 32px;
          color: #FFFFFF;
          background: @active-text-color;
          border-radius: 30px;
        }
      }
      .prize-img{
        background: #D7E7FF;
        border-radius: 5px;
        width: 34px;
        height: 34px;
        margin-right: 20px;
      }
    }
    .prize-addOrdel{
      display: flex;
      margin-left: 20px;
      .prize-del,
      .prize-add{
        width: 28px;
        height: 28px;
        cursor: pointer;
      }
      .prize-del{
        margin-right: 10px;
      }
    }
  }
  .draw-button{
    margin: 70px 36px 0 0;
    &::before{
      content: '';
      position: absolute;
      left: 50%;
      transform: translate(-50%, -15px);
      width: 100%;
      height: 1px;
      background-color: #F1F1F1;

    }
    .el-form-item__content{
      line-height: 30px;
      display: flex;
      justify-content: flex-end;
    }
    .el-button{
      width: 88px;
      height: 30px;
      line-height: 28px;
      text-align: center;
      border-radius: 30px;
      padding: 0;
      min-height: auto;
      font-size: 13px;
      border: none;
      &.is-disabled{
        background: #999;
        color: #fff;
        opacity: 0.8;
        border: none;
      }
    }
    .get-link{
      border: 1px solid @active-text-color;
      color: @active-text-color;
      background-color: @colorWhite;
    }
    .go-draw{
      color: @colorWhite;
      background: @active-text-color;
      margin-left: 12px;
    }
  }
  .select-user-dialog{
    background: #F6F9FA;
    border-radius: 6px;
    .el-dialog__header{
      padding: 16px 24px;
    }
    .el-dialog__body{
      padding: 0px 24px 12px;
      font-size: 13px;
      color: @default-text-color;
    }
    .el-dialog__title{
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
    }
    .el-dialog__footer{
      padding: 0 25px 12px;
    }
  }
  .create-link-dialog{
    margin-top: 35vh !important;
    border-radius: 6px;
    .el-dialog__header{
      padding: 20px 20px 10px 23px;
      .title{
        position: relative;
        p{
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;
          color: #000000;
        }
        .close-icon{
          position: absolute;
          top: 0;
          right: 0px;
          cursor: pointer;
          width: 10px;
          height: 10px;
          background: url(../../../../assets/img/prize/close-icon.svg);
        }
      }
    }
    .el-dialog__body{
      padding: 34px 24px;
      .copy-content{
        display: flex;
        justify-content: space-between;
        width: 508px;
        min-height: 40px;
        background: @bgColor;
        border: 1px solid #F1F1F1;
        border-radius: 6px;
        p{
          display: flex;
          align-items: center;
          padding: 6px 6px 6px 23px;
          line-height: 18px;
          color: @second-text-color

        }
        .el-button{
          width: 80px;
          min-width: 80px;
          background: #7972F0;
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
          text-align: center;
          padding: 0;
          border: none;
          outline: none;
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: auto;
          border-radius: 0px 6px 6px 0px;
        }
      }
    }
    .el-dialog__footer{
      padding: 0 24px 16px 20px;
    }
  }
  .button-box{
    .el-button{
      width: 85px;
      height: 32px;
      border-radius: 30px;
      line-height: 30px;
      padding: 0;
      border: none;
    }
    .back-button{
      color: @active-text-color;
      background: #E4E3FC;
    }
    .sure-button{
      color: #FFFFFF;
      background: @active-text-color;
    }
  }
}

</style>
